import React, { FC, useState, useEffect, useRef } from "react";
import { Modal, FormGroup, Button, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { Store } from "redux/store";
import { useAsyncRequest, useFormError, ToastError } from "components";
import { deleteBooking, approveBooking } from "api/bookings";
import { capitalizeString } from "utils/general";
import {
  BookingDTO,
  DeleteBookingDTO,
  DeleteBookingInputDTO,
  ApproveBookingDTO,
  ApproveBookingInputDTO,
} from "types/bookings";

const EditBooking: FC<EditBookingProps> = (props) => {
  const { showModal, closeModal, booking } = props;

  const approveBookingAsync = useAsyncRequest<ApproveBookingDTO>(approveBooking, false);
  const deleteBookingAsync = useAsyncRequest<DeleteBookingDTO>(deleteBooking, false);

  const userId = useSelector((state: Store) => state.account.user?._id);
  const userRole = useSelector((state: Store) => state.account.user?.role);

  useEffect(() => {
    if (approveBookingAsync.status === "success" || deleteBookingAsync.status === "success")
      closeModal(true);
  }, [approveBookingAsync.status, deleteBookingAsync.status]);

  const formatOwner = (): string => {
    if (booking?.user_id === userId) return "Me";
    if (booking?.user) {
      return `${capitalizeString(booking.user.firstname)} ${capitalizeString(
        booking.user.lastname
      )}`;
    }
    return "Other Member";
  };

  const executeDeleteBooking = (data: DeleteBookingInputDTO) => {
    deleteBookingAsync.execute(data);
  };

  const executeApproveBooking = (data: ApproveBookingInputDTO) => {
    approveBookingAsync.execute(data);
  };

  return (
    <>
      <ToastError
        mode="server"
        header="Approve Booking Error"
        errorMessage={approveBookingAsync.error}
        inModal
      />
      <ToastError
        mode="server"
        header="Delete Booking Error"
        errorMessage={deleteBookingAsync.error}
        inModal
      />
      <Modal show={showModal} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>View Booking</Modal.Title>
        </Modal.Header>
        {booking && (
          <div className="m-3">
            <label htmlFor="endDate" className="ps-2">
              Booked By:
            </label>
            <input
              name="endDate"
              readOnly={true}
              value={formatOwner()}
              className="form-control"
            ></input>
            <label htmlFor="endDate" className="ps-2 mt-2">
              Booking Status:
            </label>
            <input
              name="endDate"
              readOnly={true}
              value={capitalizeString(booking?.status)}
              className="form-control"
            ></input>
            <label htmlFor="startDate" className="ps-2 mt-2">
              Start Date:
            </label>
            <input
              name="startDate"
              readOnly={true}
              value={booking ? format(new Date(booking.startDate), "dd/MM/yyyy") : undefined}
              className="form-control"
            ></input>
            <label htmlFor="endDate" className="ps-2 mt-2">
              End Date:
            </label>
            <input
              name="endDate"
              readOnly={true}
              value={booking ? format(new Date(booking.endDate), "dd/MM/yyyy") : undefined}
              className="form-control"
            ></input>
            <label htmlFor="endDate" className="ps-2 mt-2">
              Rooms Booked:
            </label>
            <input
              name="endDate"
              readOnly={true}
              value={booking?.rooms
                .sort()
                .map((r) => `Room ${r}`)
                .join(", ")}
              className="form-control"
            ></input>
          </div>
        )}
        <Modal.Footer>
          <Col>
            {userRole === "BOOKINGS" && booking?.status === "PENDING" && (
              <Button
                variant="success"
                className="me-2"
                onClick={() => {
                  if (booking?._id) executeApproveBooking({ _id: booking._id });
                }}
              >
                Approve Booking
              </Button>
            )}
            {(userRole === "BOOKINGS" || userId === booking?.user_id) && (
              <Button
                variant="danger"
                onClick={() => {
                  if (booking?._id) executeDeleteBooking({ _id: booking._id });
                }}
              >
                Delete Booking
              </Button>
            )}
          </Col>
          <Col xs="auto" className="flex-row-reverse">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface EditBookingProps {
  showModal: Boolean;
  closeModal: (refetch?: Boolean) => void;
  booking: BookingDTO | null;
}

export default EditBooking;
