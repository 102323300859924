import React from "react";
import { FormState } from "react-hook-form";

export default (formState: FormState<any>, field: string) => {
  return (
    <>
      {formState.errors[field] && (
        <div className="form-error mt-1">{formState.errors[field].message}</div>
      )}
    </>
  );
};
