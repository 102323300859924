import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useStore } from "react-redux";
import { useHistory, Redirect } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import { updateAccount } from "redux/accountSlice";
import { RequestPasswordResetInput } from "types/account";
import { requestPasswordReset } from "api/account";
import { setShowHeader } from "redux/uiSlice";
import { useFormError, ExitButton } from "components";
import { Store } from "redux/store";

import "./account.sass";

const RequestReset: FC = (props) => {
  const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, formState } = useForm<RequestPasswordResetInput>();

  useEffect(() => {
    dispatch(setShowHeader({ showHeader: false }));
  }, []);

  const onSubmit = async (input: RequestPasswordResetInput) => {
    try {
      await requestPasswordReset(input);
      toast.success("If your email is found, we will send a password reset link shortly");
      // history.push("/");
    } catch (e) {
      toast.error("Failed to contact server");
    }
  };

  return (
    <div className="account-pages-bg">
      <ExitButton />
      <Container className="account-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="fs-1 mb-2 justify-content-center">
            <Col xs={12}>Password Reset</Col>
          </Row>
          <Row className="fs-5 justify-content-center mb-2">
            <Col xs={12}>
              Please enter your email, if we can find it, we will send a password reset link to it
              shortly.
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Please enter your email address",
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email address does not meet the expected pattern",
                  },
                })}
                placeholder="Email"
              />
              {useFormError(formState, "email")}
            </Col>
          </Row>
          <Row className="justify-content-center mt-3 mb-3">
            <Col xs={12}>
              <button className="account-filled-button w-100" type="submit">
                Send reset password link
              </button>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default RequestReset;
