import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface AuthState {
  loggedIn: boolean;
  jwt: string;
  refreshingJwt: boolean;
}

// Define the initial state using that type
const initialState: AuthState = {
  loggedIn: false,
  jwt: "",
  refreshingJwt: false,
};

// Create a redux toolkit slice
export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    logIn: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
      state.loggedIn = true;
      // state.loggedIn = false;
    },
    logOut: (state) => {
      state.jwt = "";
      state.loggedIn = false;
    },
    refreshJwtAction: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setRefreshingJwt: (state, action: PayloadAction<boolean>) => {
      state.refreshingJwt = action.payload;
    },
  },
});

// Export the actions, you can import these to dispatch an action
export const { logIn, logOut, refreshJwtAction, setRefreshingJwt } = authSlice.actions;

export default authSlice.reducer;
