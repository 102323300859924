import React, { FC, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import { MappedCalendarDayData } from "types/bookings";
import { RoomDisplay, CalendarContext } from "components";

const CalendarDay: FC<CalendarDayProps> = (props) => {
  const { date, dateNum, month, bookings } = props.data;
  const { currentMonth, index, length, openAddModal } = props;

  const [borderRight, setBorderRight] = useState<Boolean>(false);
  const [borderBottom, setBorderBottom] = useState<Boolean>(false);

  useEffect(() => {
    if ((index + 1) % 7 === 0) setBorderRight(true);
    else setBorderRight(false);

    if (length - index <= 7) setBorderBottom(true);
    else setBorderBottom(false);
  }, [index, length]);

  return (
    <Col
      className={`day-item seven-a-row flex-column ${
        currentMonth === month ? "current-month" : "other-month"
      }
      ${borderRight ? "day-border-right" : ""} ${borderBottom ? "day-border-bottom" : ""}`}
      onClick={() => openAddModal(date)}
    >
      <Row>
        <Col className="text-start">{dateNum}</Col>
      </Row>
      <RoomDisplay roomBooking={bookings["room1"]} room={1} />
      <RoomDisplay roomBooking={bookings["room2"]} room={2} />
      <RoomDisplay roomBooking={bookings["room3"]} room={3} />
      <RoomDisplay roomBooking={bookings["room4"]} room={4} />
    </Col>
  );
};

// ${currentMonth === }

interface CalendarDayProps {
  data: MappedCalendarDayData;
  currentMonth?: number;
  index: number;
  length: number;
  openAddModal: (argo0?: Date) => void;
}

export default CalendarDay;
