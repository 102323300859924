import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router";

import { ExitButton } from "components";
import { Store } from "redux/store";
import { setShowHeader } from "redux/uiSlice";
import "./account.sass";

const Unapproved: FC = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowHeader({ showHeader: false }));
  }, []);

  const { user } = useStore<Store>().getState().account;
  const history = useHistory<{ next: string }>();

  useEffect(() => {
    if (user?.status !== "PENDING") {
      history.push("/");
    }
  }, [user?.status]);

  return (
    <div className="account-pages-bg">
      <ExitButton />
      <Container className="account-container">
        <Row className="fs-1 justify-content-center mb-2">
          <Col xs={12}>Your account is awaiting manual approval by the Bookings Officer</Col>
        </Row>
        <Row className="fs-5 justify-content-center mt-4">
          <Col xs={12}>
            <Link to="/" className="account-filled-button w-100">
              Back
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Unapproved;
