import jwt_decode from "jwt-decode";

import { RequestError } from "types/request";
import {
  SignInInputDTO,
  SignInDTO,
  SignUpInputDTO,
  SignUpDTO,
  VerifyInput,
  VerifyDTO,
  RefreshDTO,
  MyAccountDTO,
  ListUsersDTO,
  ApproveUserInput,
  ApproveUserDTO,
  RequestPasswordResetInput,
  PerformPasswordResetInput,
} from "types/account";
import axiosSetup from "./axiosConfig";
import store from "redux/store";
import { refreshJwtAction, logOut, logIn } from "redux/authSlice";
import { setAccount } from "redux/accountSlice";

const axios = axiosSetup();

// Fetch requests are being used to skip inteceptor
export const signIn = async (signInInput: SignInInputDTO): Promise<SignInDTO> => {
  const response = await fetch("/api/auth/signin/", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(signInInput),
  });
  const data = (await response.json()) as SignInDTO;
  return data;
};

// Fetch requests are being used to skip inteceptor
export const signUp = async (signUpInput: SignUpInputDTO): Promise<SignUpDTO> => {
  const response = await fetch("/api/auth/signup/", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(signUpInput),
  });
  const data = (await response.json()) as SignUpDTO;
  return data;
};

export const verifyAccount = async (verifyInput: VerifyInput): Promise<VerifyDTO> => {
  const response = await axios.post<VerifyDTO>("/api/auth/verify/", verifyInput);
  return response.data;
};

export const refreshCode = async (): Promise<RefreshDTO> => {
  const response = await axios.get<RefreshDTO>("/api/auth/refreshcode/");
  return response.data;
};

export const myAccount = async (): Promise<MyAccountDTO> => {
  const response = await axios.get<MyAccountDTO>("/api/users/me/");
  return response.data;
};

export const listAccounts = async (): Promise<ListUsersDTO> => {
  const response = await axios.get<ListUsersDTO>("/api/users/");
  return response.data;
};

export const approveAccount = async (input: ApproveUserInput): Promise<ApproveUserDTO> => {
  const response = await axios.post<ApproveUserDTO>("/api/users/approve/", input);
  return response.data;
};

export const requestPasswordReset = async (input: RequestPasswordResetInput): Promise<{}> => {
  const response = await axios.post<{}>("/api/auth/resetpassword/", input);
  return response.data;
};

export const performPasswordReset = async (input: PerformPasswordResetInput): Promise<{}> => {
  const response = await axios.post<{}>("/api/auth/resetpassword/perform/", input);
  return response.data;
};
