import { FC, useEffect, useRef } from "react";
import store from "redux/store";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory, Redirect } from "react-router";
import { useStore, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import { setShowHeader } from "redux/uiSlice";
import { useAsyncRequest, ToastError, useFormError, ExitButton } from "components";
import { signUp } from "api/account";
import { logIn } from "redux/authSlice";
import "./account.sass";

const SignUp: FC = (props) => {
  const { data, execute, status, error } = useAsyncRequest(signUp, false);
  const { register, handleSubmit, formState, watch } = useForm({ mode: "onBlur" });
  const dispatch = useDispatch();
  const { loggedIn } = useStore().getState().auth;

  const onSubmit = (formFields: any) => {
    execute(formFields);
  };

  const history = useHistory<{ next: string }>();

  useEffect(() => {
    dispatch(setShowHeader({ showHeader: false }));
  }, []);

  useEffect(() => {
    if (status === "success") {
      if (data?.token) dispatch(logIn(data.token));
    }
  }, [data, status]);

  useEffect(() => {
    if (loggedIn) {
      if (history.location.state && history.location.state.next)
        history.push(history.location.state.next);
      else history.push("/");
    }
  }, [loggedIn]);

  const password = useRef({});
  password.current = watch("password", "");

  return (
    <div className="account-pages-bg">
      <ToastError mode="server" header="Create Account Error" errorMessage={error} />
      <ExitButton />
      <Container className="account-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="fs-1 mb-2 justify-content-center">
            <Col xs={12}>Sign Up</Col>
          </Row>
          <Row className="fs-5 justify-content-center mb-2">
            <Col xs={12}>
              Sign Up to access Power House Aqua Club’s online booking and membership pages
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Please enter your email address",
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email address does not meet the expected pattern",
                  },
                })}
                placeholder="Email"
              />
              {useFormError(formState, "email")}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("firstname", {
                  required: { value: true, message: "Please enter your first name" },
                })}
                placeholder="First Name"
              ></input>
              {useFormError(formState, "firstname")}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("lastname", {
                  required: { value: true, message: "Please enter your last name" },
                })}
                placeholder="Last Name"
              ></input>
              {useFormError(formState, "lastname")}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("password", {
                  required: { value: true, message: "Please enter your password" },
                })}
                placeholder="Password"
                type="password"
              ></input>
              {useFormError(formState, "password")}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("confirmPassword", {
                  required: { value: true, message: "Please enter your confirmation password" },
                  validate: (confirmPassword) => {
                    return (
                      password.current === confirmPassword ||
                      "Confirmation password and Password must match"
                    );
                  },
                })}
                placeholder="Confirm Password"
                type="password"
              ></input>
              {useFormError(formState, "confirmPassword")}
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12}>
              <button className="account-filled-button w-100" type="submit">
                Create an Account
              </button>
            </Col>
          </Row>
          <div className="fs-5 mt-1">
            Please note: All accounts need to be manually approved by the booking officer
          </div>
          {error}
        </form>
      </Container>
    </div>
  );
};

export default SignUp;
