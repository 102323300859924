import React, { FC, useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { flexRender, getCoreRowModel, useReactTable, ColumnDef } from "@tanstack/react-table";
import { Button, Container } from "react-bootstrap";

import { IUserDTO } from "types/account";
import { listAccounts, approveAccount } from "api/account";

import "./Accounts.sass";

const Accounts: FC = () => {
  const [users, setUsers] = useState<IUserDTO[]>([]);

  const getUsers = async () => {
    try {
      const res = await listAccounts();
      if (res.users) setUsers(res.users);
      else toast.error("Failed to get users");
    } catch (e) {
      toast.error("Failed to get users");
      console.error(e);
    }
    setUpdate(update + 1);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const [update, setUpdate] = useState<number>(0); // temp method to force update

  const postApproveAccount = async (_id: string) => {
    try {
      const res = await approveAccount({ _id });
      if (res.user?.status === "ACTIVE") {
        toast.success("User has been approved");
      } else {
        toast.error("Failed to approve user");
      }
      getUsers();
    } catch (e) {
      toast.error("Failed to approve user");
      console.error(e);
    }
  };

  const columns = useMemo<ColumnDef<IUserDTO>[]>(
    () => [
      {
        header: "First Name",
        accessorKey: "firstname",
      },
      {
        header: "Last Name",
        accessorKey: "lastname",
      },
      {
        header: "Status",
        accessorKey: "status",
      },
      {
        header: "Email",
        accessorKey: "email",
      },
      {
        header: "Role",
        accessorKey: "role",
      },
      {
        header: "Controls",
        cell: ({ row: { original } }) => {
          return (
            <>
              {original.status === "PENDING" ? (
                <Button variant="warning" onClick={() => postApproveAccount(original._id)}>
                  Approve User
                </Button>
              ) : (
                "none"
              )}
            </>
          );
        },
      },
    ],
    [update]
  );

  const table = useReactTable<IUserDTO>({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <h2>Power House Aqua Club Users</h2>
      <table className="admin-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default Accounts;
