import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface UIState {
  showHeader: boolean;
}

// Define the initial state using that type
const initialState: UIState = {
  showHeader: true,
};

// Create a redux toolkit slice
export const uiSlice = createSlice({
  name: "ui",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setShowHeader: (state, action: PayloadAction<UIState>) => {
      state.showHeader = action.payload.showHeader;
    },
  },
});

// Export the actions, you can import these to dispatch an action
export const { setShowHeader } = uiSlice.actions;

export default uiSlice.reducer;
