import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import Store from "redux/store";
import { setRefreshingJwt } from "redux/authSlice";

const axiosSetup = () => {
  const axiosInstance = axios.create({});
  // Appends Auth token to all requests
  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    config.headers.Authorization = `Bearer ${Store.getState().auth.jwt}`;
    return config;
  });
  return axiosInstance;
};

export default axiosSetup;
