import { FC, useState, useEffect } from "react";
import { Toast, ToastBody, ToastContainer } from "react-bootstrap";

const ToastError: FC<ToastErrorProps> = (props) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const { errorMessage, header, inModal } = props;

  useEffect(() => {
    if (errorMessage !== null) setShowToast(true);
    else setShowToast(false);
  }, [errorMessage]);

  return (
    <ToastContainer
      className="position-fixed bottom-0 end-0 m-5 bg-danger"
      style={inModal ? { zIndex: 1060 } : {}}
    >
      <Toast show={showToast} onClose={() => setShowToast(false)}>
        <Toast.Header>
          <strong className="w-100 text-start">{header}</strong>
        </Toast.Header>
        <ToastBody>{errorMessage}</ToastBody>
      </Toast>
    </ToastContainer>
  );
};

interface ToastErrorProps {
  mode: "server";
  header: string;
  errorMessage: string | null;
  inModal?: boolean;
}

export default ToastError;
