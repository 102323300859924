import { FC, useEffect, useState, createContext } from "react";
import store from "redux/store";
import { Row, Col, Container } from "react-bootstrap";
import { format, addMonths, eachDayOfInterval, getDate, getMonth } from "date-fns";

import { setShowHeader } from "redux/uiSlice";
import { timeframeStartEnd, updateBookings, setupBookings, parseDateString } from "utils/bookings";
import {
  CalendarEvents,
  CalendarLegend,
  AddBooking,
  EditBooking,
  CalendarContext,
  useAsyncRequest,
  useBreakpoints,
} from "components";
import { sm } from "config";
import { MappedCalendarDayData, RoomBookings, BookingDTO } from "types/bookings";
import { getBookings } from "api/bookings";

import "./calendar.sass";

const Calendar: FC = (props) => {
  useEffect(() => {
    store.dispatch(setShowHeader({ showHeader: true }));
  }, []);

  const [date, setDate] = useState<Date>(new Date());

  const [dates, setDates] = useState<{ startDate: Date; endDate: Date }>();
  useEffect(() => {
    setDates(timeframeStartEnd(date));
  }, [date]);

  const { data, execute, status, error } = useAsyncRequest(getBookings, false);
  useEffect(() => {
    if (dates?.startDate && dates?.endDate) {
      execute({ startDate: dates.startDate, endDate: dates.endDate });
    }
  }, [dates]);

  const [mappedDateArray, setMappedDateArray] = useState<MappedCalendarDayData[]>();

  useEffect(() => {
    if (dates?.startDate && dates?.endDate) {
      const dayArray = eachDayOfInterval({ start: dates.startDate, end: dates.endDate });
      setMappedDateArray(
        dayArray.map((day): MappedCalendarDayData => {
          let bookings: RoomBookings = setupBookings();

          data?.bookings?.forEach((booking) => {
            const from = parseDateString(booking.startDate);
            const to = parseDateString(booking.endDate);

            if (day >= from && day <= to) {
              if (format(from, "dd/MM/yyyy") === format(day, "dd/MM/yyyy")) {
                bookings = updateBookings(bookings, booking, "arvo");
              } else if (format(to, "dd/MM/yyyy") === format(day, "dd/MM/yyyy")) {
                bookings = updateBookings(bookings, booking, "morn");
              } else {
                bookings = updateBookings(bookings, booking, "morn");
                bookings = updateBookings(bookings, booking, "arvo");
              }
            }
          });

          return {
            date: day,
            dateNum: getDate(day),
            month: getMonth(day),
            bookings: bookings,
          };
        })
      );
    }
  }, [data, dates]);

  const [dateDisplay, setDateDisplay] = useState<string>();

  useEffect(() => {
    setDateDisplay(format(date, "MMMM, yyy"));
  }, [date]);

  const [showAddModal, setShowAddModal] = useState<Boolean>(false);
  const [addPresetDate, setAddPresetDate] = useState<Date | undefined>(new Date());
  const openAddModal = (day?: Date) => {
    setAddPresetDate(day);
    setShowAddModal(true);
  };
  const closeAddModal = (refetch?: Boolean) => {
    setShowAddModal(false);
    if (refetch && dates?.startDate && dates?.endDate)
      execute({ startDate: dates.startDate, endDate: dates.endDate });
  };

  const [showEditModal, setShowEditModal] = useState<Boolean>(false);
  const [selectedBooking, setSelectedBooking] = useState<BookingDTO | null>(null);
  const openEditModal = (booking: BookingDTO) => {
    setSelectedBooking(booking);
    setShowEditModal(true);
  };
  const closeEditModal = (refetch?: Boolean) => {
    setSelectedBooking(null);
    setShowEditModal(false);
    if (refetch && dates?.startDate && dates?.endDate)
      execute({ startDate: dates.startDate, endDate: dates.endDate });
  };

  const [hoveredBookingID, setHoveredBookingID] = useState<string>();

  const width = useBreakpoints();

  return (
    <>
      <AddBooking
        showModal={showAddModal}
        closeModal={closeAddModal}
        startDateDefault={addPresetDate}
      />
      <EditBooking
        showModal={showEditModal}
        closeModal={closeEditModal}
        booking={selectedBooking}
      />
      <CalendarContext.Provider
        value={{
          openEditModal: openEditModal,
          hoveredBookingID: hoveredBookingID,
          setHoveredBookingID: setHoveredBookingID,
        }}
      >
        <Container>
          <Row className={width > sm ? "justify-content-between" : "justify-content-center"}>
            <Col xs="auto">
              <div className="d-flex">
                <div className="left-arrow" onClick={() => setDate(addMonths(date, -1))}></div>
                <div className="fs-1 ms-3 me-3 user-select-none text-nowrap">{dateDisplay}</div>
                <div className="right-arrow" onClick={() => setDate(addMonths(date, 1))}></div>
              </div>
            </Col>
            <Col xs="auto">
              <CalendarLegend openModal={openAddModal} />
            </Col>
          </Row>
          <CalendarEvents
            date={date}
            mappedDateArray={mappedDateArray}
            status={status}
            openAddModal={openAddModal}
          />
        </Container>
      </CalendarContext.Provider>
    </>
  );
};

// format(day, "MMM")

export default Calendar;
