import { FC } from "react";
import { Row, Col } from "react-bootstrap";

import { useBreakpoints } from "components";
import { lg } from "config";

const CalendarLegend: FC<CalendarLegendProps> = (props) => {
  const { openModal } = props;

  const width = useBreakpoints();

  const displayRoom = (number: number, colour: string) => (
    <Col>
      <span className={`legend-item legend-item-${colour}`}>
        {width > lg ? "Room" : "R"} {number}
      </span>
    </Col>
  );

  return (
    <Row className="justify-content-end">
      <Col xs="auto" className="fs-5 text-align-start">
        <Row>
          {displayRoom(1, "orange")}
          {displayRoom(2, "green")}
        </Row>
        <Row>
          {displayRoom(3, "yellow")}
          {displayRoom(4, "blue")}
        </Row>
      </Col>
      <Col xs="auto">
        <button
          type="button"
          className="fs-3 add-btn ps-4 pe-4 br-3 p-2 rounded"
          onClick={() => openModal()}
        >
          Add
        </button>
      </Col>
    </Row>
  );
};

interface CalendarLegendProps {
  openModal: () => void;
}

// format(day, "MMM")

export default CalendarLegend;
