import { FC, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory, Redirect } from "react-router";
import { useStore, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import { setShowHeader } from "redux/uiSlice";
import { logIn } from "redux/authSlice";
import { setAccount } from "redux/accountSlice";
import { signIn } from "api/account";
import { useAsyncRequest, useFormError, ToastError, ExitButton } from "components";
import { SignInInputDTO } from "types/account";
import { useBreakpoints } from "components";
import { lg } from "config";

import "./account.sass";

const Login: FC = (props) => {
  const { data, execute, status, error } = useAsyncRequest(signIn, false);
  const { register, handleSubmit, formState } = useForm();
  const dispatch = useDispatch();
  const { loggedIn } = useStore().getState().auth;

  const onSubmit = (data: SignInInputDTO) => {
    execute(data);
  };

  const history = useHistory<{ next: string }>();

  useEffect(() => {
    dispatch(setShowHeader({ showHeader: false }));
  }, []);

  useEffect(() => {
    if (data?.token && data?.user) {
      dispatch(logIn(data.token));
      dispatch(setAccount(data.user));
    }
  }, [data]);

  useEffect(() => {
    if (loggedIn) {
      if (history.location.state && history.location.state.next)
        history.push(history.location.state.next);
      else history.push("/");
    }
  }, [loggedIn]);

  const width = useBreakpoints();

  return (
    <div className="account-pages-bg">
      <ToastError mode="server" header="Login Error" errorMessage={error} />
      <ExitButton />
      <Container className="account-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="fs-1 mb-2 justify-content-center">
            <Col xs={12}>Sign In</Col>
          </Row>
          <Row className="fs-5 justify-content-center mb-2">
            <Col xs={12}>
              Sign In to access Power House Aqua Club’s online booking and membership pages
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Please enter your email address",
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email address does not meet the expected pattern",
                  },
                })}
                placeholder="Email"
              />
              {useFormError(formState, "email")}
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("password", {
                  required: { value: true, message: "Please enter your password" },
                })}
                placeholder="Password"
                type="password"
              />
              {useFormError(formState, "password")}
            </Col>
          </Row>
          {width > lg ? (
            <Row className="justify-content-center  mt-3">
              <Col xs={7}>
                <button type="submit" className="account-filled-button w-100">
                  Log In
                </button>
              </Col>
              <Col>
                <div className="account-seperator-vertical"></div>
              </Col>
              <Col xs={4}>
                <Link to="/signup" className="account-outlined-button w-100" role="button">
                  Sign Up
                </Link>
              </Col>
            </Row>
          ) : (
            <>
              <Row className="justify-content-center">
                <Col xs={12}>
                  <button type="submit" className="account-filled-button w-100">
                    Log In
                  </button>
                </Col>
              </Row>
              <Row className="mt-2 pb-1 mb-3">
                <Col xs={12}>
                  <div className="account-seperator"></div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12}>
                  <Link to="/signup" className="account-outlined-button w-100" role="button">
                    Sign Up
                  </Link>
                </Col>
              </Row>
            </>
          )}
          <Row className="fs-5 mt-3">
            <Link to="/reset">Forgotten your password?</Link>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default Login;
