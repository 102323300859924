import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import uiReducer, { UIState } from "./uiSlice";
import authReducer, { AuthState } from "./authSlice";
import accountReducer, { AccountState } from "./accountSlice";

// import {combineReducers} from "redux";

// const reducers = combineReducers({
//  //...
// });

const persistConfig = {
  key: "root",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    ui: uiReducer,
    auth: persistedAuthReducer,
    account: accountReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// const store = configureStore({
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== 'production',
//   middleware: [thunk]
// });

// export default store;

export interface Store {
  ui: UIState;
  auth: AuthState;
  account: AccountState;
}

export default store;
