import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, parseJSON } from "date-fns";

import { RoomBookings, BookingDTO, RoomBooking } from "types/bookings";

export const timeframeStartEnd = (date: Date): { startDate: Date; endDate: Date } => {
  const monthStart = startOfMonth(date);
  const monthEnd = endOfMonth(date);
  const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
  const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
  return { startDate, endDate };
};

export const parseDateString = (dateString: string): Date => {
  const date = parseJSON(dateString);
  return new Date(date.setHours(0, 0, 0, 0));
};

export const setupBookings = (): RoomBookings => {
  return {
    room1: {
      morn: undefined,
      arvo: undefined,
    },
    room2: {
      morn: undefined,
      arvo: undefined,
    },
    room3: {
      morn: undefined,
      arvo: undefined,
    },
    room4: {
      morn: undefined,
      arvo: undefined,
    },
  };
};

export const updateBookings = (
  bookings: RoomBookings,
  booking: BookingDTO,
  mode: "morn" | "arvo"
): RoomBookings => {
  booking.rooms.forEach((room) => {
    let roomObj = returnRoomObject(room, bookings);
    roomObj[mode] = booking;
  });
  return bookings;
};

const returnRoomObject = (room: Number, bookings: RoomBookings): RoomBooking => {
  switch (room) {
    case 1:
      return bookings.room1;
    case 2:
      return bookings.room2;
    case 3:
      return bookings.room3;
    case 4:
      return bookings.room4;
    default:
      return bookings.room1;
  }
};
