import { FC, useEffect } from "react";
import store from "redux/store";
import { Row, Col, Container } from "react-bootstrap";

import { setShowHeader } from "redux/uiSlice";

import "./Home.scss";
import homeBanner from "assets/homeBanner.png";

const Home: FC = (props) => {
  useEffect(() => {
    store.dispatch(setShowHeader({ showHeader: true }));
  }, []);

  return (
    <>
      <div>
        <img src={homeBanner} className="img-fluid"></img>
      </div>
      <Container>
        <Row>
          <Col xs={12} lg={6} className="fs-5 p-3">
            Power House Aqua Club is based at Gully Lodge, nestled on the shores of Lake Eppalock.
            The lodge has four bedrooms that each sleep five, and is available for members' use
            throughout the year. It is a great location for action-packed water sports, or just some
            time relaxing time ‘away from it all’.
          </Col>
          <Col xs={12} lg={6} className="fs-5 p-3">
            For existing members, the members area is loaded with useful information, and we are
            using an online availability and booking system.
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
