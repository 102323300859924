import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useStore } from "react-redux";
import { useHistory, Redirect } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import { updateAccount } from "redux/accountSlice";
import { VerifyInput, IUserDTO } from "types/account";
import { verifyAccount, refreshCode } from "api/account";
import { setShowHeader } from "redux/uiSlice";
import { useFormError, useAsyncRequest, ToastError, ExitButton } from "components";
import { Store } from "redux/store";
import "./account.sass";

const maxAttempts = 3;

const Verify: FC = (props) => {
  const dispatch = useDispatch();
  const { user } = useStore<Store>().getState().account;
  const history = useHistory<{ next: string }>();

  useEffect(() => {
    if (user?.status === "PENDING") {
      history.push("/unapproved");
    } else if (user?.status === "DEACTIVATED") {
      history.push("/deactivated");
    } else if (user?.status !== "UNVERIFIED") {
      history.push("/");
    }
  }, [user?.status]);

  const { register, handleSubmit, formState } = useForm<VerifyInput>();

  useEffect(() => {
    dispatch(setShowHeader({ showHeader: false }));
  }, []);

  const onSubmit = async (data: { code: string }) => {
    const code = parseInt(data.code);
    try {
      const data = await verifyAccount({ code });
      if (data?.user) {
        dispatch(updateAccount(data.user as Partial<IUserDTO>));
        if (data.user.status === "UNVERIFIED" && data.user.verify.attempts < maxAttempts) {
          toast.error("Invalid code, please try again");
        }
        if (data?.user.status === "PENDING") {
          toast.success("Successfully verified email");
        }
      }
    } catch (e) {
      toast.error("Failed to verify code, please try refreshing your code");
    }
  };

  const [refreshButtonPressed, setRefreshButtonPressed] = useState<Boolean>(false);
  const [refreshButtonRef, setRefreshButtonRef] = useState<NodeJS.Timeout | null>();

  const executeRefreshCode = async () => {
    try {
      if (!refreshButtonPressed) {
        setRefreshButtonPressed(true);
        const data = await refreshCode();
        dispatch(updateAccount(data.user as Partial<IUserDTO>));
        toast.success("Successfully refreshed code");
      }
    } catch (e) {
      toast.error("Failed to refresh code, please try again or contact the bookings officer");
    }
  };

  useEffect(() => {
    if (refreshButtonPressed) {
      setRefreshButtonRef(
        setTimeout(() => {
          setRefreshButtonPressed(false);
        }, 30000)
      );
    }

    return () => {
      if (refreshButtonRef) {
        clearTimeout(refreshButtonRef);
        setRefreshButtonRef(null);
      }
    };
  }, [refreshButtonPressed]);

  return (
    <div className="account-pages-bg">
      <ExitButton />
      <Container className="account-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="fs-1 mb-2 justify-content-center">
            <Col xs={12}>Email Verification</Col>
          </Row>
          <Row className="fs-5 justify-content-center mb-2">
            <Col xs={12}>Please enter the code from the email we sent to your email address.</Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("code", {
                  required: { value: true, message: "Please enter your verification code" },
                  pattern: { value: /^\d+$/, message: "Code must be a postive integer" },
                })}
                placeholder="Code"
                type="number"
                maxLength={5}
              />
              {useFormError(formState, "code")}
            </Col>
          </Row>
          <Row className="justify-content-center mt-3 mb-3">
            <Col xs={12}>
              <button className="account-filled-button w-100" type="submit">
                Verify
              </button>
            </Col>
          </Row>
          {user?.verify?.attempts && user.verify.attempts >= maxAttempts ? (
            <Row className="justify-content-center text-danger mt-3 mb-3">
              <Col xs={12}>Please refresh your code and enter the new code you recieve</Col>
            </Row>
          ) : (
            <></>
          )}
          <Row className="justify-content-center mt-3 mb-3">
            <Col xs={12}>
              <a
                className={`fs-5 mt-1 user-select-none ${
                  refreshButtonPressed ? "refresh-pressed" : ""
                }`}
                onClick={() => {
                  executeRefreshCode();
                  return false;
                }}
              >
                Resend verification email
              </a>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default Verify;
