import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { IconContext } from "react-icons";
import { AiOutlineMenu } from "react-icons/ai";

import { Store } from "redux/store";
import { logOut } from "redux/authSlice";
import { forgetAccount } from "redux/accountSlice";
import { capitalizeString } from "utils/general";
import { useBreakpoints } from "components";
import { lg } from "config";

import "./Header.scss";

const Header: FC = (props) => {
  const dispatch = useDispatch();
  const showHeader = useSelector((state: Store) => state.ui.showHeader);
  const loggedIn = useSelector((state: Store) => state.auth.loggedIn);
  const name = useSelector((state: Store) => state.account.user?.firstname);
  const accountRole = useSelector((state: Store) => state.account.user?.role);

  const location = useLocation();

  const width = useBreakpoints();

  return (
    <div>
      {showHeader && (
        <Navbar>
          <Container>
            <Nav.Link
              as={Link}
              to="/"
              className={`navbar-brand fs-3 nav-font ${
                location.pathname === "/" && "nav-selected"
              }`}
            >
              {width <= lg ? "PHAC" : "Power House Aqua Club"}
            </Nav.Link>
            <Nav className="justify-content-end flex-grow-1">
              <Navbar.Text className={`pe-3 ${width <= lg ? "" : "navbar-welcome"}`}>
                {name && `Logged in as: ${capitalizeString(name)}`}
              </Navbar.Text>

              {width <= lg ? (
                <NavDropdown
                  title={
                    <IconContext.Provider value={{ size: "2rem", className: "me-3" }}>
                      <AiOutlineMenu />
                    </IconContext.Provider>
                  }
                  align="end"
                >
                  <NavDropdown.Item as={Link} to="/bookings">
                    Bookings
                  </NavDropdown.Item>
                  {loggedIn ? (
                    <NavDropdown.Item
                      as={Link}
                      to="#"
                      onClick={() => {
                        dispatch(logOut());
                        dispatch(forgetAccount());
                      }}
                    >
                      Log out
                    </NavDropdown.Item>
                  ) : (
                    <NavDropdown.Item as={Link} to="/login">
                      Login
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              ) : (
                <>
                  {loggedIn ? (
                    <Navbar.Brand
                      as={Link}
                      to="#"
                      onClick={() => {
                        dispatch(logOut());
                        dispatch(forgetAccount());
                      }}
                      className="fs-3 nav-font"
                      role="button"
                    >
                      Log out
                    </Navbar.Brand>
                  ) : (
                    <Navbar.Brand as={Link} to="/login" className="fs-3 nav-font">
                      Login
                    </Navbar.Brand>
                  )}
                  <Navbar.Brand
                    as={Link}
                    to="/bookings"
                    className={`fs-3 ps-2 pe-3 br-3 rounded pb-2 nav-btn ${
                      location.pathname === "/bookings" && "nav-selected nav-btn-selected"
                    }`}
                  >
                    Bookings
                  </Navbar.Brand>
                  {(accountRole === "BOOKINGS" || accountRole === "ADMIN") && (
                    <NavDropdown
                      title={
                        <IconContext.Provider value={{ size: "2rem", className: "me-3" }}>
                          <AiOutlineMenu />
                        </IconContext.Provider>
                      }
                      align="end"
                    >
                      <NavDropdown.Item as={Link} to="/accounts">
                        Accounts
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                </>
              )}
            </Nav>
          </Container>
        </Navbar>
      )}
    </div>
  );
};

export default Header;
