import React, { FC } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { GrClose } from "react-icons/gr";

const ExitButton: FC = (props) => {
  return (
    <Link to="/" className="position-absolute top-0 end-0 p-5">
      <IconContext.Provider value={{ size: "2rem", className: "me-3" }}>
        <GrClose />
      </IconContext.Provider>
    </Link>
  );
};

export default ExitButton;
