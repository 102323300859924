import { FC, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useStore } from "react-redux";
import { useHistory, Redirect } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { updateAccount } from "redux/accountSlice";
import { PerformPasswordResetInput } from "types/account";
import { performPasswordReset } from "api/account";
import { setShowHeader } from "redux/uiSlice";
import { useFormError, ExitButton } from "components";
import { Store } from "redux/store";

import "./account.sass";

const RequestReset: FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, formState, watch } =
    useForm<Partial<PerformPasswordResetInput>>();

  useEffect(() => {
    dispatch(setShowHeader({ showHeader: false }));
  }, []);

  const { token } = useParams<{ token: string }>();

  const onSubmit = async (partialInput: Partial<PerformPasswordResetInput>) => {
    try {
      const { password, confirmPassword } = partialInput;
      if (password && confirmPassword) {
        await performPasswordReset({ token, password, confirmPassword });
        toast.success("Password successfully reset, please login");
        history.push("/login");
      } else toast.error("Required field not found");
    } catch (e) {
      toast.error("Failed to perform password reset");
    }
  };

  const password = useRef<undefined | string>(undefined);
  password.current = watch("password", "");

  return (
    <div className="account-pages-bg">
      <ExitButton />
      <Container className="account-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="fs-1 mb-2 justify-content-center">
            <Col xs={12}>Set New Password</Col>
          </Row>
          <Row className="fs-5 justify-content-center mb-2">
            <Col xs={12}>Please enter your new password and press confirm to save it.</Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("password", {
                  required: { value: true, message: "Please enter your password" },
                })}
                placeholder="Password"
                type="password"
              ></input>
              {useFormError(formState, "password")}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <input
                className="account-input"
                {...register("confirmPassword", {
                  required: { value: true, message: "Please enter your confirmation password" },
                  validate: (confirmPassword) => {
                    return (
                      password.current === confirmPassword ||
                      "Confirmation password and Password must match"
                    );
                  },
                })}
                placeholder="Confirm Password"
                type="password"
              ></input>
              {useFormError(formState, "confirmPassword")}
            </Col>
          </Row>
          <Row className="justify-content-center mt-3 mb-3">
            <Col xs={12}>
              <button className="account-filled-button w-100" type="submit">
                Confirm new password
              </button>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default RequestReset;
