import { FC, useEffect, useState } from "react";
import store from "redux/store";
import { eachDayOfInterval, getMonth, getDate, parseJSON, format } from "date-fns";
import { Row, Col, Spinner } from "react-bootstrap";

import { setShowHeader } from "redux/uiSlice";
import { timeframeStartEnd, updateBookings, setupBookings, parseDateString } from "utils/bookings";
import { CalendarDay, useAsyncRequest, useBreakpoints } from "components";
import { MappedCalendarDayData, RoomBookings } from "types/bookings";
import { getBookings } from "api/bookings";
import { lg } from "config";

const CalendarEvents: FC<CalendarEventsProps> = (props) => {
  useEffect(() => {
    store.dispatch(setShowHeader({ showHeader: true }));
  }, []);

  const { date, mappedDateArray, status, openAddModal } = props;

  const [currentMonth, setCurrentMonth] = useState<number>();
  useEffect(() => {
    setCurrentMonth(getMonth(date));
  }, [date]);

  const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const width = useBreakpoints();

  return (
    <Row className="calendar-container mt-1">
      {status === "pending" && (
        <div className="calendar-spinner-container">
          <Spinner
            className="calendar-loading-spinner"
            animation="border"
            role="status"
            variant="secondary"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Row className="pe-0">
        {dayNames.map((dayName) => (
          <Col className="seven-a-row fs-5" key={dayName}>
            {width > lg ? dayName : dayName.slice(0, 3)}
          </Col>
        ))}
      </Row>
      <Row className="pe-0">
        {mappedDateArray &&
          mappedDateArray.map((dateItem, index) => (
            <CalendarDay
              data={dateItem}
              currentMonth={currentMonth}
              key={dateItem.date.getTime()}
              index={index}
              length={mappedDateArray.length}
              openAddModal={openAddModal}
            />
          ))}
      </Row>
    </Row>
  );
};

interface CalendarEventsProps {
  date: Date;
  openAddModal: (argo0?: Date) => void;
  mappedDateArray?: MappedCalendarDayData[];
  status: "idle" | "error" | "pending" | "success";
}

export default CalendarEvents;
