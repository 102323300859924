import { useState, useEffect, useCallback } from "react";

// Nabbed from: https://usehooks.com/useAsync/, feel free to modify however we want
const useAsync = <T, E = string>(
  asyncFunction: (requestData: any) => Promise<T>,
  immediate = true
) => {
  const [status, setStatus] = useState<"idle" | "pending" | "success" | "error">("idle");
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<E | string | null>(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, data, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (requestData) => {
      setStatus("pending");
      setData(null);
      setError(null);
      return asyncFunction(requestData)
        .then((response: any) => {
          if (response.errors?.message) {
            setError(response.errors.message);
            setStatus("error");
          } else {
            setData(response);
            setStatus("success");
          }
        })
        .catch((response: any) => {
          console.error("Catch error");

          if (response.errors?.message) setError(response.errors?.message);
          else if (response.response?.data?.errors?.message)
            setError(response.response?.data?.errors?.message);
          else setError("An unknown error occured");
          setStatus("error");
        });
    },
    [asyncFunction]
  );
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute(null);
    }
  }, [execute, immediate]);
  return { execute, status, data, error };
};

export default useAsync;
