import { IUserDTO } from "types/account";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface AccountState {
  user?: IUserDTO;
}

// Define the initial state using that type
// Default is undefined
const initialState: AccountState = {
  user: undefined,
};

// Create a redux toolkit slice
export const accountSlice = createSlice({
  name: "account",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAccount: (state, action: PayloadAction<IUserDTO | undefined>) => {
      // TODO: Remove undefined from type
      state.user = action.payload;
    },
    updateAccount: (state, action: PayloadAction<Partial<IUserDTO>>) => {
      if (state.user) state.user = { ...state.user, ...action.payload };
    },
    forgetAccount: (state) => {
      state.user = undefined;
    },
  },
});

// Export the actions, you can import these to dispatch an action
export const { setAccount, updateAccount, forgetAccount } = accountSlice.actions;

export default accountSlice.reducer;
