import React, { FC, useEffect } from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Store } from "redux/store";
import { UserRoles } from "types/account";

const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
  const loggedIn = useSelector((state: Store) => state.auth.loggedIn);
  const accountStatus = useSelector((state: Store) => state.account.user?.status);
  const accountRole = useSelector((state: Store) => state.account.user?.role);
  const history = useHistory();

  useEffect(() => {
    if (!loggedIn) history.push("/login", { next: props.path });
  });

  return (
    <>
      {loggedIn ? (
        accountStatus === "UNVERIFIED" ? (
          <Redirect to="/verify" />
        ) : accountStatus === "PENDING" ? (
          <Redirect to="/unapproved" />
        ) : accountStatus === "DEACTIVATED" ? (
          <Redirect to="/deactivated" />
        ) : props.accountRole === "BOOKINGS" ? (
          accountRole === "BOOKINGS" || accountRole === "ADMIN" ? (
            <Route {...props} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Route {...props} />
        )
      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};

interface ProtectedRouteProps extends RouteProps {
  accountRole?: UserRoles;
}

export default ProtectedRoute;
