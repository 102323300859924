import axiosSetup from "./axiosConfig";

import {
  CreateBookingDTO,
  CreateBookingInputDTO,
  GetBookingsInputDTO,
  GetBookingsDTO,
  DeleteBookingInputDTO,
  DeleteBookingDTO,
  ApproveBookingInputDTO,
  ApproveBookingDTO,
} from "types/bookings";

const axios = axiosSetup();

export const createBooking = async (input: CreateBookingInputDTO): Promise<CreateBookingDTO> => {
  const response = await axios.post<CreateBookingDTO>("/api/bookings/", input);
  return response.data;
};

export const getBookings = async (input: GetBookingsInputDTO): Promise<GetBookingsDTO> => {
  const response = await axios.get<GetBookingsDTO>("/api/bookings/", { params: input });
  return response.data;
};

export const deleteBooking = async (input: DeleteBookingInputDTO): Promise<DeleteBookingDTO> => {
  const response = await axios.delete<DeleteBookingDTO>("/api/bookings/", { data: input });
  return response.data;
};

export const approveBooking = async (input: ApproveBookingInputDTO): Promise<ApproveBookingDTO> => {
  const response = await axios.post<ApproveBookingDTO>("/api/bookings/approve/", input);
  return response.data;
};
