import React, { FC, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

import { Store } from "../redux/store";
import {
  Header,
  Home,
  Login,
  SignUp,
  Calendar,
  ProtectedRoute,
  Unapproved,
  Verify,
  Deactivated,
  Accounts,
  RequestReset,
  PerformReset,
} from ".";
import { setAccount } from "redux/accountSlice";
import { logOut } from "redux/authSlice";
import { myAccount } from "api/account";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

const App: FC = () => {
  const loggedIn = useSelector((state: Store) => state.auth.loggedIn);
  const user = useSelector((state: Store) => state.account.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn && !user) {
      myAccount()
        .then((res) => {
          dispatch(setAccount(res.user));
        })
        .catch(() => {
          dispatch(logOut());
        });
    }
  }, [loggedIn, user]);

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Header></Header>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/verify" component={Verify} />
          <Route exact path="/deactivated" component={Deactivated} />
          <Route exact path="/unapproved" component={Unapproved} />
          <Route exact path="/reset" component={RequestReset} />
          <Route exact path="/reset/:token" component={PerformReset} />
          <ProtectedRoute exact path="/bookings" component={Calendar} />
          <ProtectedRoute exact path="/accounts" component={Accounts} accountRole="BOOKINGS" />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
