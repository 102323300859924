import React, { FC, useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { BookingDTO, RoomBooking } from "types/bookings";
import { CalendarContext } from "components";

const RoomDisplay: FC<RoomDisplayProps> = (props) => {
  const { roomBooking, room } = props;
  const { morn, arvo } = roomBooking;
  const [sameBooking, setSameBooking] = useState<Boolean>(false);

  useEffect(() => {
    if (morn && arvo && morn._id === arvo._id) setSameBooking(true);
    else setSameBooking(false);
  }, [morn, arvo]);

  const { hoveredBookingID, setHoveredBookingID, openEditModal } = useContext(CalendarContext);

  const onClickHandler = (booking: BookingDTO | undefined) => (e: any) => {
    if (typeof openEditModal === "function" && booking) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      openEditModal(booking);
    }
  };

  const onMouseEnterHandler = (booking: BookingDTO | undefined) => () => {
    if (typeof setHoveredBookingID === "function" && booking) setHoveredBookingID(booking._id);
  };

  const onMouseLeaveHandler = () => {
    if (typeof setHoveredBookingID === "function") setHoveredBookingID();
  };

  // TODO: Check if bookings are different
  return (
    <Row>
      {sameBooking ? (
        <Col
          className={`room-booking room-${room} active ${
            morn?.status === "APPROVED" ? "approved" : "pending"
          } ${hoveredBookingID && hoveredBookingID === morn?._id ? "room-hover" : ""}`}
          onClick={onClickHandler(morn)}
          onMouseEnter={onMouseEnterHandler(morn)}
          onMouseLeave={onMouseLeaveHandler}
        ></Col>
      ) : (
        <>
          <Col
            className={`room-booking room-${room} room-morn ${
              morn ? `active ${morn.status === "APPROVED" ? "approved" : "pending"}` : ""
            } ${hoveredBookingID && hoveredBookingID === morn?._id ? "room-hover" : ""}`}
            onClick={onClickHandler(morn)}
            onMouseEnter={onMouseEnterHandler(morn)}
            onMouseLeave={onMouseLeaveHandler}
          ></Col>
          <Col
            className={`room-booking room-${room} room-arvo ${
              arvo ? `active ${arvo.status === "APPROVED" ? "approved" : "pending"}` : ""
            } ${hoveredBookingID && hoveredBookingID === arvo?._id ? "room-hover" : ""}`}
            onClick={onClickHandler(arvo)}
            onMouseEnter={onMouseEnterHandler(arvo)}
            onMouseLeave={onMouseLeaveHandler}
          ></Col>
        </>
      )}
    </Row>
  );
};

interface RoomDisplayProps {
  roomBooking: RoomBooking;
  room: number;
}

export default RoomDisplay;
