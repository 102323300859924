import React, { FC, useState, useEffect, useRef } from "react";
import { Modal, FormGroup, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useSelector } from "react-redux";
import { format, addDays, startOfDay } from "date-fns";

import { Store } from "redux/store";
import { useAsyncRequest, useFormError, ToastError } from "components";
import { createBooking } from "api/bookings";
import { CreateBookingDTO, CreateBookingInputDTO } from "types/bookings";

const roomOptions = [
  { value: 1, label: "Room 1" },
  { value: 2, label: "Room 2" },
  { value: 3, label: "Room 3" },
  { value: 4, label: "Room 4" },
];

const AddBooking: FC<AddBookingProps> = (props) => {
  const { showModal, closeModal, startDateDefault } = props;

  const { data, execute, status, error } = useAsyncRequest<CreateBookingDTO>(createBooking, false);
  const { register, handleSubmit, formState, control, watch, setValue } = useForm();

  const startDate = useRef({});
  startDate.current = watch("startDate", "");

  const onSubmit = (e: any) => {
    execute(e);
  };

  const [selectedOption, setSelectedOption] = useState<any>([]);
  const handleTypeSelect = (e: any) => {
    setSelectedOption(e);
  };
  const userRole = useSelector((state: Store) => state.account.user?.role);

  useEffect(() => {
    if (status === "success") onCloseModal(true);
  }, [status]);

  const onCloseModal = (refetch?: boolean) => {
    setSelectedOption([]);
    closeModal(refetch);
  };

  useEffect(() => {
    let newDate;
    if (startDateDefault) newDate = startDateDefault;
    else newDate = new Date();

    setValue("startDate", format(newDate, "yyyy-MM-dd"));
    setValue("endDate", format(addDays(newDate, 2), "yyyy-MM-dd"));
  }, [startDateDefault]);

  return (
    <>
      <ToastError mode="server" header="Create Booking Error" errorMessage={error} inModal />
      <Modal show={showModal} onHide={onCloseModal} backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Add a Booking</Modal.Title>
          </Modal.Header>
          <div className="m-3">
            <label htmlFor="startDate" className="form-label">
              Start Date
            </label>
            <input
              type="date"
              className="form-control"
              {...register("startDate", {
                required: {
                  value: true,
                  message: "Please select a start date",
                },
                validate: (startDate) => {
                  return startDate >= startOfDay(new Date()) || "Start Date cannot be before today";
                },
                valueAsDate: true,
              })}
            />
            <div id="startDateHelp" className="form-text">
              {useFormError(formState, "startDate")}
            </div>
          </div>
          <div className="m-3">
            <label htmlFor="endDate" className="form-label">
              End Date
            </label>
            <input
              type="date"
              className="form-control"
              {...register("endDate", {
                required: {
                  value: true,
                  message: "Please select an end date",
                },
                validate: (endDate) => {
                  return startDate.current < endDate || "Start Date must be less then End Date";
                },
                valueAsDate: true,
              })}
            />
            <div id="endDateHelp" className="form-text">
              {useFormError(formState, "endDate")}
            </div>
          </div>
          <div className="m-3">
            <label htmlFor="rooms" className="form-label">
              Rooms
            </label>
            <Controller
              name="rooms"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select at least one room",
                },
              }}
              render={({ field: { onChange, value, ref, name } }) => (
                <Select
                  name="rooms"
                  ref={ref}
                  isMulti
                  options={
                    userRole === "USER"
                      ? selectedOption.length < 2
                        ? roomOptions
                        : []
                      : roomOptions
                  }
                  noOptionsMessage={() =>
                    "You have selected the maximum number of rooms for a single account"
                  }
                  onChange={(val) => {
                    onChange(val.map((v) => v.value));
                    handleTypeSelect(val);
                  }}
                />
              )}
            />
            <input type="hidden" value="test" name="fgfhf" />
            <div id="roomsHelp" className="form-text">
              {useFormError(formState, "rooms")}
            </div>
          </div>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                onCloseModal();
              }}
            >
              Close
            </Button>
            <Button type="submit" className="create-booking-btn">
              Create Booking
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

interface AddBookingProps {
  showModal: Boolean;
  closeModal: (refetch?: Boolean) => void;
  startDateDefault?: Date;
}

export default AddBooking;
